import React, { useState } from 'react';
import { Music2 } from 'lucide-react';
import ReactPlayer from 'react-player';
import { ref, set } from 'firebase/database';
import { db } from '../firebase/config';
import { MusicProps } from '../HomePage';

const YOUTUBE_URL_REGEX =
  /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(&.*)?$/;

interface MusicSectionProps {
  music: MusicProps;
  hasMusicToday: boolean;
  userId: string;
}

const MusicSection: React.FC<MusicSectionProps> = ({
  hasMusicToday,
  music,
  userId,
}) => {
  const [musicLink, setMusicLink] = useState('');

  const handleMusicSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userId || hasMusicToday || !musicLink) return;

    try {
      set(ref(db, '/music'), {
        link: musicLink,
        platform: 'youtube',
        lastUserId: userId,
      });
      setMusicLink('');
    } catch (error) {
      console.error('Error adding music:', error);
    }
  };

  return (
    <section className="bg-white rounded-lg shadow-md p-6">
      {hasMusicToday && <p>Music for today has already been shared</p>}
      <div className="flex items-center gap-2 mb-4">
        <Music2 className="w-6 h-6 text-indigo-600" />
        <h2 className="text-xl font-semibold">Today's Music</h2>
      </div>
      <form onSubmit={handleMusicSubmit} className="space-y-4">
        {!hasMusicToday && (
          <input
            type="text"
            value={musicLink}
            onChange={(e) => setMusicLink(e.target.value)}
            placeholder="Paste your music link here..."
            className="w-full p-3 border rounded-lg"
            disabled={hasMusicToday}
          />
        )}
        {music.link && (
          <ReactPlayer
            playing
            loop
            url={music.link}
            controls
            width="100%"
            height="320px"
          />
        )}
        <button
          type="submit"
          disabled={
            hasMusicToday || !musicLink || !YOUTUBE_URL_REGEX.test(musicLink)
          }
          className="bg-indigo-600 text-white px-4 py-2 rounded-sm  disabled:opacity-50">
          Share Music
        </button>
        <p className="text-sm text-gray-500">
          *You can only set music once consecutively. To set music again, you
          need to wait for the some other user. Only YouTube links are supported
        </p>
      </form>
    </section>
  );
};

export default MusicSection;
