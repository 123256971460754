import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyD8dAv7lBCx4nRjqTtdZ4rOmD5-7wy0IKw',
  authDomain: 'mota-9e3d4.firebaseapp.com',
  databaseURL: 'https://mota-9e3d4-default-rtdb.firebaseio.com',
  projectId: 'mota-9e3d4',
  storageBucket: 'mota-9e3d4.appspot.com',
  messagingSenderId: '973339128694',
  appId: '1:973339128694:web:2888266da7c09d5a418057',
  measurementId: 'G-TB6QHHJQWF',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getDatabase(app);
