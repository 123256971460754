import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../utils';

export const useSetAnalytics = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  const deviceInfo = {
    operatingSystem: detectOperatingSystem(),
    deviceType: detectDeviceType(),
    browser: detectBrowser(),
  };

  function detectOperatingSystem() {
    if (userAgent.includes('windows')) return 'Windows';
    if (userAgent.includes('mac os')) return 'Mac OS';
    if (userAgent.includes('android')) return 'Android';
    if (userAgent.includes('iphone') || userAgent.includes('ipad'))
      return 'iOS';
    if (userAgent.includes('linux')) return 'Linux';
    return 'Unknown OS';
  }

  function detectDeviceType() {
    if (userAgent.includes('mobile')) return 'Mobile';
    if (userAgent.includes('tablet')) return 'Tablet';
    return 'Desktop';
  }

  function detectBrowser() {
    if (userAgent.includes('chrome')) return 'Chrome';
    if (userAgent.includes('safari') && !userAgent.includes('chrome'))
      return 'Safari';
    if (userAgent.includes('firefox')) return 'Firefox';
    if (userAgent.includes('edge')) return 'Edge';
    return 'Unknown Browser';
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      logEvent(
        analytics,
        `new_landing_page_visited_by_${deviceInfo.deviceType}`,
        {
          ...deviceInfo,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
