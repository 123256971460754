import React from 'react';
import { Calendar } from 'lucide-react';
import { format } from 'date-fns';

import { Message } from '../HomePage';

interface MessageHistoryProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  messages: Record<string, Message>;
  isAdmin: boolean;
}

const MessageHistory: React.FC<MessageHistoryProps> = ({
  selectedDate,
  onDateChange,
  messages,
  isAdmin,
}) => {
  const msgArr = Object.values(messages);

  return (
    <section className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-2 mb-4">
        <Calendar className="w-6 h-6 text-indigo-600" />
        <h2 className="text-xl font-semibold">Message History</h2>
      </div>
      <input
        type="date"
        value={format(selectedDate, 'yyyy-MM-dd')}
        onChange={(e) => onDateChange(new Date(e.target.value))}
        className="w-full p-3 border rounded-lg mb-4"
      />
      {msgArr.length !== 0 ? (
        <div className="space-y-4">
          {msgArr.map((msg) => (
            <div key={msg.createdById} className="bg-gray-50 p-4 rounded-lg">
              <p className="text-gray-800">{msg.title}</p>
              {isAdmin && msg.createdByName && (
                <p className="text-sm text-gray-500 mt-2">
                  Written by: {msg.createdByName}
                </p>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No messages for today</p>
      )}
    </section>
  );
};

export default MessageHistory;
