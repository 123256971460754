import React, { useState } from 'react';

type Todo = {
  isChecked: boolean;
  text: string;
  id: number;
};

export const Admin = () => {
  const [todos] = useState<Todo[]>([]);

  return (
    <div>
      Todo:
      {todos.map((todo) => (
        <div key={todo.id}>
          <div
            style={{ height: '8px', width: '8px', border: '1px solid black' }}
          />
          <div>{todo.text}</div>
          <div>{todo.isChecked.toString()}</div>
        </div>
      ))}
    </div>
  );
};
