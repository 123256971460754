import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useSignOut } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';

const LoginButton: React.FC = () => {
  const [signOut] = useSignOut(auth);
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      signInWithPopup(auth, provider).then(async (res) => {
        if (
          res?.user?.email &&
          ['mehul.ajmer97@gmail.com', 'khushi551997@gmail.com'].includes(
            res.user.email,
          )
        ) {
          // only then allow auth to login
          return res;
        } else {
          await signOut();
        }
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <button
        onClick={signInWithGoogle}
        className="flex items-center gap-2 bg-white px-6 py-3 rounded-lg shadow-md hover:shadow-lg transition-shadow">
        Sign in with Google
      </button>
    </div>
  );
};

export default LoginButton;
