import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import * as Pages from './pages';
import MusicDiary from './pages/music-diary/HomePage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MusicDiary />,
  },
  // {
  //   path: '/',
  //   element: <Pages.LandingPage />,
  // },
  { path: '/admin', element: <Pages.Admin /> },
  { path: '/gallery', element: <Pages.Gallery /> },
  {
    path: '/*',
    element: <h1>404 Not found </h1>,
  },
]);
