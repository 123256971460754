import React, { useState } from 'react';
import { MessageSquare } from 'lucide-react';
import dayjs from 'dayjs';
import { ref, set } from 'firebase/database';
import { db } from '../firebase/config';

interface MessageInputProps {
  userId: string;
  userEmail: string;
  hasPostedToday: boolean;
}

const MessageInput: React.FC<MessageInputProps> = ({
  userId,
  userEmail,
  hasPostedToday,
}) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleMessageSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userId || !message || message.length > 300) return;
    setIsLoading(true);

    try {
      set(ref(db, `messages/${dayjs().format('DD-MM-YYYY')}/${userId}`), {
        title: message.trim(),
        createdByName: userEmail,
        createdById: userId,
        createdAt: dayjs().valueOf(),
      });
      setMessage('');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error adding message:', error);
    }
  };

  return (
    <section className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-2 mb-4">
        <MessageSquare className="w-6 h-6 text-indigo-600" />
        <h2 className="text-xl font-semibold">Write a Message</h2>
      </div>
      <form onSubmit={handleMessageSubmit} className="space-y-4">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write your message here... (300 characters max)"
          className="w-full p-3 border rounded-lg h-32"
          maxLength={300}
          disabled={hasPostedToday}
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-500">
            {message.length}/300 characters
          </span>
          <button
            type="submit"
            disabled={
              hasPostedToday ||
              !message ||
              message.length > 300 ||
              !message.trim()
            }
            className="bg-indigo-600 text-white px-4 py-2 rounded-lg disabled:opacity-50">
            Post Message
          </button>
        </div>
      </form>
      {/* <p className="text-sm text-gray-500">
        *You can only write 1 message per day
      </p> */}
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}></div>
      )}
    </section>
  );
};

export default MessageInput;
