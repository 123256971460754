import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import { get, child, ref, onValue } from 'firebase/database';
import { auth, db } from './firebase/config';
import LoginButton from './components/LoginButton';
import MusicSection from './components/MusicSection';
import MessageHistory from './components/MessageHistory';
import MessageInput from './components/MessageInput';
import { useSetAnalytics } from '../../hooks';

export interface MusicProps {
  link?: string;
  platform?: string;
  lastUserId?: string;
}

export interface Message {
  title?: string;
  createdByName?: string;
  createdById?: string;
  createdAt?: string;
}

const HomePage = () => {
  const dbRef = ref(db);
  const [user] = useAuthState(auth);
  const [signOut, loading] = useSignOut(auth);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [messages, setMessages] = useState<Record<string, Message>>({});
  const [hasPostedToday, setHasPostedToday] = useState(false);
  const [music, setMusic] = useState<MusicProps>();
  useSetAnalytics();

  const onSignOut = async () => {
    if (!loading) await signOut();
  };

  const fetchMusic = async () => {
    get(child(dbRef, '/music'))
      .then((snapshot) => {
        snapshot.exists() && setMusic(snapshot.val() as MusicProps);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMessages = async () => {
    if (!user) return;

    get(child(dbRef, `/messages/${dayjs().format('DD-MM-YYYY')}`))
      .then((snapshot) => {
        const val = snapshot.val();
        val && setMessages(val as Record<string, Message>);
        val && setHasPostedToday(val[user.uid] !== undefined);
      })
      .catch((err) => {
        console.log('error fetching messages', err);
      });
  };

  const listenMusicChanges = () => {
    onValue(child(dbRef, '/music'), (snapshot) => {
      snapshot.exists() && setMusic(snapshot.val() as MusicProps);
    });
  };

  const listenMessagesChanges = () => {
    onValue(
      child(dbRef, `/messages/${dayjs().format('DD-MM-YYYY')}`),
      (snapshot) => {
        const val = snapshot.val();
        val && setMessages(val as Record<string, Message>);
        val && user?.uid && setHasPostedToday(val[user.uid] !== undefined);
      },
    );
  };
  useEffect(() => {
    fetchMusic();
    fetchMessages();
    listenMusicChanges();
    listenMessagesChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, user]);

  if (!user) {
    return <LoginButton />;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto space-y-8 home-page-container">
        <button
          className="bg-red-600 text-white px-4 py-2 rounded-sm disabled:opacity-50 place-self-end"
          onClick={onSignOut}>
          Logout
        </button>
        <MusicSection
          userId={user.uid}
          hasMusicToday={music?.lastUserId === user.uid}
          music={music || {}}
        />
        <MessageHistory
          selectedDate={selectedDate}
          onDateChange={setSelectedDate}
          messages={messages}
          isAdmin={user.email === 'mehul.ajmer97@gmail.com'}
        />
        <MessageInput
          userId={user.uid}
          userEmail={user.email || ''}
          hasPostedToday={hasPostedToday}
        />
      </div>
      <p className="text-sm text-gray-300">
        Note- This webpage or the information of this webpage cannot be used in
        any place, or cannot be shared with anyone without the owner's consent
      </p>
    </div>
  );
};

export default HomePage;
